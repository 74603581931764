import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BreadCrumbs from "../../components/BreadCrumbs";
import {Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image";
import ComplexCodeSample from "../../components/CodeSnippets/ComplexCodeSample";
import SignalToNoiseGut from "../../components/CodeSnippets/SignalToNoiseGut";
import GruseligeExceptions from "../../components/CodeSnippets/GruseligeExceptions";

export default function SimpleFailsLess() {
    const breadcrumbs = [
        {name: 'Artikel', href: '/Artikel', current: false},
        {name: 'Simple Fails Less', href: '/Artikel/SimpleFailsLess', current: true},
    ]

    return (
        <>
            <Layout>
                <Seo title="Artikel: Simple Fails Less"/>
                <BreadCrumbs pages={breadcrumbs}/>
                <div className="relative py-16 bg-white overflow-hidden max-w-3xl mx-auto mt-10 sm:rounded-lg">
                    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                            <svg
                                className="absolute top-12 left-full transform translate-x-32"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                            >
                                <defs>
                                    <pattern
                                        id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                            </svg>
                            <svg
                                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                            >
                                <defs>
                                    <pattern
                                        id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                            </svg>
                            <svg
                                className="absolute bottom-12 left-full transform translate-x-32"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                            >
                                <defs>
                                    <pattern
                                        id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                            </svg>
                        </div>
                    </div>
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="text-lg max-w-prose mx-auto">
                            <h1>
                                <span
                                    className="block text-base text-center text-blue-500 font-semibold tracking-wide uppercase">
              Weniger Komplexität — weniger Bugs
            </span>
                                <span
                                    className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Simple Fails Less
            </span>
                            </h1>
                            <p className="mt-8 text-xl text-gray-500 leading-8">
                                Ich entwickle nun mehr als 20 Jahre Software und
                                <span className="font-medium italic">»Simple Fails Less«</span> ist die auf 3 Worte
                                komprimierte, persönliche Erkenntnis daraus. Weil ich diese Erkenntnis für fundamental
                                wichtig halte, habe ich sie zu meinem Firmenmotto gemacht und sie auch auf meine
                                Visitenkarte geschrieben.
                            </p>
                        </div>
                        <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                            <h2>Kontrolle von Komplexität</h2>
                            <p>Meine Erkenntnis aus der Software-Entwicklung ist, dass Komplexität eine der größten
                                Risikofaktoren ist. Am Anfang eines neuen Projekts (grüne Wiese) ist noch alles
                                überschaubar und einfach. Jeder überblickt noch alles und ist zuversichtlich. Doch die
                                Software wird über Monate und Jahre weiterentwickelt und damit steigt praktisch immer
                                die Komplexität:</p>
                            <ul>
                                <li>Neue Methoden werden in Klassen hinzugefügt.</li>
                                <li>Methoden werden verlängert.</li>
                                <li>Neue Fallunterscheidungen / Ausnahmen müssen ergänzt werden.</li>
                            </ul>
                            <p>Und bevor man sich versieht, überschreitet die Code-Kapazität die Gehirnkapazität.</p>
                            <div className="flex items-center justify-center">

                                <StaticImage
                                    src="../../images/komplexitaet1.png"
                                    formats={["auto", "webp", "avif"]}
                                    alt="Wenn die Code-Komplexität größer ist als die Gehirnkapazität"
                                    className=""


                                />

                            </div>
                            <p>Aber bevor wir über theoretische Sachen sprechen, schauen wir uns doch mal ein konkretes
                                Beispiel an. Man beachte im folgenden Bild die Verschachtelungstiefe mit <span
                                    className="font-medium italic">if-else</span> und <span
                                    className="font-medium italic">foreach</span>. Versuchen Sie doch mal, die folgenden
                                Fragen zu beantworten:</p>
                            <ul>
                                <li>Was genau macht der Code?</li>
                                <li>Ist man hier zuversichtlich, dass man alle Eventualitäten überblickt?</li>
                                <li>Hat man hier ein gutes Gefühl, eine Erweiterung einzubauen?</li>
                            </ul>
                            <div className="flex items-center justify-center text-sm">


                                <ComplexCodeSample/>
                            </div>

                            <p>In diesem — zugegebenermaßen krassen — Beispiel überschreitet die Code-Komplexität die
                                Gehirnkomplexität bei Weitem. Hier wurde sicherlich der Zeitpunkt für eine
                                Refakturierung lange verpasst.</p>

                            <p>Aber woran genau liegt es, dass dieser Code so komplex ist? Denken Sie mal auf einer ganz
                                hohen Abstraktionsebene darüber nach. Als ich mal mit großem Abstand auf so einen Code
                                geschaut habe, hat es »Klick!« gemacht und ich wusste die Antwort. Es ist ganz einfach:
                                Man sieht den Wald vor lauter Bäumen nicht.</p>
                            <h2>Signal-to-Noise Ratio</h2>
                            <p>In der Physik gibt es die Messgröße <span className="italic font-medium">Signal-to-Noise Ratio</span>,
                                die ein ähnliches Phänomen beschreibt.
                                In der Praxis möchte man am liebsten ganz viel Signal und wenig Noise haben. Das
                                Signal-to-Noise Ratio gibt an, wie das Verhältnis von »Signal« (will man viel von haben)
                                und »Noise« (will man wenig von haben) ist. Mit anderen Worten: Je höher das
                                Signal-to-Noise Ratio desto besser.</p>
                            <p>Auf die Programmierung übertragen bedeutet das:</p>
                            <ul>
                                <li><span className="font-bold">Signal:</span> Verständnis, was die eigentlichen
                                    Aktionen im Code sind
                                </li>
                                <li><span className="font-bold">Noise:</span> Alles, was dem Verständnis abträglich ist
                                </li>
                            </ul>
                            <p>Schauen Sie sich mal den nachfolgenden Code an und versuchen Sie zu verstehen, was dieser
                                macht.</p>
                            <SignalToNoiseGut/>
                            <p>Ich nehme an, dass Sie keine große Schwierigkeiten hatten, zu erkennen, was die Absicht
                                dieses Codes ist. Das liegt vor allem daran, dass ganz viel »Signal« da ist, also
                                Codezeilen, bei denen Sie sofort erkennen, was sie machen sollen. Und es gibt ganz wenig
                                »Noise«, was dem Verständnis abträglich ist.</p>
                            <p>Stellen Sie sich jetzt vor, dass die Methoden <span
                                className="italic font-medium">FindeBenutzer() </span> und <span
                                className="italic font-medium">ErzeugeLebenslauf() </span>
                                ebenfalls nach diesem Muster gebaut sind; dann wird das Lesen und Verstehen des
                                Codes ebenfalls keine Probleme bereiten.</p>
                            <p>Ich stelle jetzt einfach mal eine These in den Raum, die ich zwar nicht endgültig
                                beweisen kann, die aber durch meine persönliche Erfahrung gestützt ist:</p>
                            <blockquote>
                                <p className="text-xl text-center">
                                    Komplexität ~ Chance auf Bugs
                                </p>
                            </blockquote>
                            <p>Oder in Worten:</p>
                            <blockquote>
                                <p className="text-xl text-center">
                                    Die Höhe der Komplexität ist proportional zu der Chance auf Bugs
                                </p>
                            </blockquote>
                            <p>Je höher also die Komplexität des Codes ist, desto mehr steigt die Wahrscheinlichkeit für
                                Bugs. Oder ganz plakativ ausgedrückt:</p>
                            <blockquote>
                                <p className="text-xl text-center">
                                    Komplexität ist der Nährboden für Bugs.
                                </p>
                            </blockquote>
                            <h2>Zwei Arten von Komplexität</h2>
                            <p>Wir haben gesehen, dass Komplexität kontraproduktiv für stabile Software ist. Da liegt es
                                nahe, dass wir analysieren, welche Arten von Komplexität es gibt und wie man diese
                                reduzieren kann.</p>
                            <StaticImage
                                src="../../images/ZweiArtenVonKomplexitaet.png"
                                formats={["auto", "webp", "avif"]}
                                alt="Zwei Arten von Komplexität"
                            />
                            <p>Wir haben also zwei grundlegende Arten von Komplexität:</p>
                            <ul>
                                <li>Die Komplexität, die dem Problem an sich innewohnt</li>
                                <li>Die Komplexität, die vom Entwickler beigesteuert wird</li>
                            </ul>
                            <p>An der ersten Komplexität kann man häufig nur schwer drehen, um sie zu verringern. Bei
                                der letzteren Komplexität hat man mehr Einfluss. Und genau darum wollen wir uns jetzt
                                kümmern. Wie können wir die unnötige Komplexität verringern? Die eine Möglichkeit habe
                                ich oben schon beschrieben, indem wir den Code einfach lesbarer gestalten. Darüber
                                hinaus habe ich zwei Komplexitätstreiber identifiziert, die großer Regelmäßigkeit in
                                Projekten für Probleme sorgen.</p>
                            <h2>Zwei weitere typische Komplexitätstreiber</h2>
                            <StaticImage
                                src="../../images/ZweiKomplexitaetstreiber.png"
                                formats={["auto", "webp", "avif"]}
                                alt="Zwei Komplexitaetstreiber in der Software-Entwicklung"
                            />

                            <p>In den meisten Projekten, in denen ich gearbeitet habe, wurden Exceptions und Null durch
                                die Bank weg eingesetzt. Kaum jemand hat irgendein Problem damit gesehen. Und doch kam
                                es praktisch immer zu Problemen mit NullReferenceExceptions oder es traten Exceptions
                                auf, um die sich niemand im Call Stack gekümmert hat. Die folgenden Abschitten
                                beleuchten, warum ich von der Verwendung von Null und Exceptions dringend abrate.</p>
                            <h3>Was ist so schlimm an »Null«?</h3>
                            <blockquote>
                                <p className="text-xl text-center">
                                    I call it my billion-dollar mistake. It has caused a billion dollars of pain and
                                    damage in the last forty years.
                                </p>
                            </blockquote>
                            <p className="font-normal text-sm">– Tony Hoare</p>
                            <p>Tony Hoare hat nach eigenen Aussagen der Versuchung nicht widerstehen können, in ALGOL
                                eine Null Reference einzubauen, weil es so einfach zu implementieren war. Dies führte in
                                unzähligen Programmen zu Fehlern, Sicherheitslücken und Systemabstürzen, deren Kosten
                                wohl in
                                Milliradenhöhe waren.</p>
                            <p>Bis heute erfreut sich Null in vielen objekt-orientierten Programmiersprachen größter
                                Beliebtheit. Warum dem so ist, kann ich beim besten Willen nicht nachvollziehen.
                                Meine Erfahrung lautet:</p>
                            <blockquote>
                                <p className="text-xl text-center">
                                    Wenn Null eingesetzt wird, kommt es zu NullReferenceExceptions — meistens zu den
                                    ungünstigsten Zeitpunkten.
                                </p>
                            </blockquote>
                            <p>Jeder Programmierer von OOP sollte doch ein großes Interesse an Objekten haben und nervös
                                werden, wenn es keine Objekte gibt. Null ist nichts, auch kein Objekt. Auf Objekten kann
                                man Operationen auslösen; wenn man das auf null macht, fliegt einem das um die
                                Ohren. Warum verwenden dann so viele Programmierer Null? Antwort: Ich habe keine Ahnung.
                                Erfolg kann es nicht sein.</p>
                            <p>Auch die Übersichtlichkeit leidet darunter, weil man ja immer auf null testen muss, wenn
                                eine Methode ein Objekt zurückliefert, das null sein kann. Und dann sind wir wieder beim
                                Signal-to-Noise Ratio.</p>
                            <p>Dass so viele Programmierer null lieben, ist auch vor einem anderen Hintergrund schwer
                                nachzuvollziehen. Die meisten C#-Programmierer lieben LINQ und wie man mit
                                Fluent-Notation IEnumerables bearbeiten kann. Man stelle sich vor, da könnte auch null
                                zurückgeliefert werden und man müsste darauf prüfen. Die Eleganz wäre sofort dahin!</p>
                            <p>Wer weitere Gründe haben möchte, warum man Null aus seinem Repertoire streichen sollte,
                                dem empfehle ich meinen Extra-Artikel über Null zu studieren.</p>
                            <h3>Was ist so schlimm an »Exceptions«?</h3>
                            <p>Für Exceptions gilt dasselbe wie für Null — nur noch schlimmer. Eine Exceptions reißt den
                                natürlichen Programmablauf aus dem Raum-Zeit-Kontinuum und spült einen im Call Stack
                                nach oben in der Hoffnung, irgend jemand kümmert sich darum. Ja mehr noch: man hofft,
                                dass diese Instanz auf kompetent genug ist, eine sinnvolle Ausnahmebehandlung zu
                                machen.</p>
                            <p>Je weiter man im Call Stack nach oben kommt, desto unwahrscheinlicher ist es, dass die
                                Ausnahme gut verarztet werden kann. Nicht selten findet man ganz oben in <span
                                    className="font-medium italic">main()</span> ein <span
                                    className="font-medium italic">try/catch all</span>,
                                das streunende Exceptions davon abhalten sollen, an die Oberfläche zu kommen.</p>
                            <p>Ich kenne keinen Entwickler, der in seinen Programmen <span
                                className="font-medium italic">goto</span> verwendet, dafür viele, die ohne mit der
                                Wimper zu zucken Exceptions einsetzen. Wenn man es genau nimmt, sind Exceptions noch
                                schlimmer als <span
                                    className="font-medium italic">goto</span>, weil man bei <span
                                    className="font-medium italic">goto</span> wenigstens genau weiß, an welcher Stelle
                                das Programm fortgesetzt wird.</p>
                            <p>Eine gruselige Anwendung von Exceptions, die gar nicht so selten ist, sieht man hier:</p>
                            <GruseligeExceptions/>
                            <p>Wenn man überhaupt Exceptions verwendet, sollte man sie nur für tatsächliche Ausnahmen
                                verwenden; wenn also das System mit etwas konfrontiert wird, mit dem es nicht gerechnet
                                hat und auch nicht mehr weitermachen kann. Dass man nach einem Benutzer sucht, den es
                                nicht
                                gibt, ist ein erwartetes Ergebnis. Dieses sollte man nicht mit einer Exception
                                quittieren. </p>
                            <p>Der Aufruf einer solchen Methode erfordert es jedes Mal, ein <span
                                className="font-medium italic">try/catch</span> zu verwenden. Und dann sind wir wieder
                                beim
                                Signal-to-Noise Ratio.</p>
                            <p>Wer weitere Gründe haben möchte, warum man Exceptions aus seinem Repertoire streichen
                                sollte,
                                empfehle ich meinen Extra-Artikel über Exceptions zu studieren.</p>
                            <p>
                                Software-Entwicklung ist immer mit Risiko verbunden. Selbst wenn ein Programm seit
                                Monaten stabil läuft, kann der Tag kommen, an dem es mit einem Fehler aussteigt. Die
                                Frage
                                ist nicht, <span className="font-medium italic underline">ob</span> das passiert,
                                sondern <span className="font-medium italic underline ">wann</span>.
                            </p>


                            <h2>Zusammenfassung</h2>
                            <p>Über das Thema Komplexität in der Software-Entwicklung könnte man sicher ganze Bücher
                                schreiben. Ich habe mich bei diesem Artikel auf ein paar wenige Aspekte konzentriert,
                                die ich in meiner täglichen Praxis in Projekten antreffe. Die wichtigsten Erkenntnisse
                                sind:</p>
                            <ul>
                                <li>Komplexität ist der Nährboden für Bugs.</li>
                                <li>Komplexität muss kontrolliert werden, bevor es zu spät ist.</li>
                                <li>Zwei Komplexitätstreiber sind »null« und »Exceptions«.</li>
                                <li>Weniger Komplexität — weniger Bugs</li>
                                <li>Weniger Bugs — mehr Akzeptanz, weniger Kosten, mehr Profit</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

